//Fix togglePicture styles 
#togglePicture #toogleElement {
    margin-left: 6px;
    margin-right: 6px;
}
@media (min-width: 425px) { // custom size, don't use this media query unless you know how this works
    .login-paper {
        min-height: auto;
        margin-top: 50px;
    }
}

@media (#{$tablet}) {
    #togglePicture #toogleElement {
        margin-left: 6px;
        margin-right: 12px;
    }
}

@media (#{$desktop}) {
    .login-paper {
        .login-paper-inner {
            margin: 50px;
        }
    }
    #togglePicture #toogleElement {
        margin-left: 6px;
        margin-right: 18px;
    }
}

@media (#{$large-desktop}) {
    #togglePicture #toogleElement {
        margin-left: 6px;
        margin-right: 12px;
    }
    .container {
        width: auto !important;
        margin-left: 7vw !important;
        margin-right: 7vw !important;
    }
    .paper-container {
        margin-top: 50px;
        margin-bottom: 30px;
        min-height: auto;
    }
}

.smart {
    width: 100%;
}

.smart thead {
    background-color: #F0F0F0;
}

.smart th {
    padding: 1em;
    font-size: 14px;
}

.smart tr {
    padding-left: 50px;
}

.smart td {
    padding: 0.5em;
    font-size: 14px;
}

.smart div.label {
    display: none;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3)
}

@media (max-width: 991px) {
    .smart thead {
        display: none;
    }

    .smart div.label {
        display: block;
    }

    .smart th,
    .smart tr,
    .smart td {
        display: block;
        width: auto !important;
    }

    .smart tr {
        padding-left: 15px;
    }
}
.help-panel {
    text-align: center;
    border-radius: $border-radius;
    background-color: $gray-200;
    height: fit-content;
    align-self: center;

    p {
        margin: 3rem 0;
        color: $primary;
    }
}
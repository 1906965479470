.power-calculator {

    .help-panel {
        margin-top: 3rem;
        padding: 2rem;
    }
}

.gridList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.appliance {
    width: 300px;
    margin: 20px;
}

.result {
    h1 {
        font-size: 25px;
        text-align: center;
        color: $primary;
    }

    .value {
        font-weight: 700;
        font-size: 84px;
        text-align: center;
        margin-bottom: 3rem;
        color: $primary;
    }

    p {
        text-align: center;
    }
}

// Mobile
@media (max-width: 767px) {
    .result {
        .value {
            font-size: 65px;
        }
    }
}
// IBM PLEX MONO
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-Thin.otf') format('truetype');
    font-style: normal;
    font-weight: 100;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-ThinItalic.otf') format('truetype');
    font-style: italic;
    font-weight: 100;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-ExtraLight.otf') format('truetype');
    font-style: normal;
    font-weight: 200;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-ExtraLightItalic.otf') format('truetype');
    font-style: italic;
    font-weight: 200;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-Light.otf') format('truetype');
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-LightItalic.otf') format('truetype');
    font-style: italic;
    font-weight: 300;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-Regular.otf') format('truetype');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-Italic.otf') format('truetype');
    font-style: italic;
    font-weight: 400;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-Medium.otf') format('truetype');
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-MediumItalic.otf') format('truetype');
    font-style: italic;
    font-weight: 500;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-SemiBold.otf') format('truetype');
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-SemiBoldItalic.otf') format('truetype');
    font-style: italic;
    font-weight: 600;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-Bold.otf') format('truetype');
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('ibm plex mono/IBMPlexMono-BoldItalic.otf') format('truetype');
    font-style: italic;
    font-weight: 700;
}
// IBM PLEX SANS
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-Thin.otf') format('truetype');
    font-style: normal;
    font-weight: 100;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-ThinItalic.otf') format('truetype');
    font-style: italic;
    font-weight: 100;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-ExtraLight.otf') format('truetype');
    font-style: normal;
    font-weight: 200;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-ExtraLightItalic.otf') format('truetype');
    font-style: italic;
    font-weight: 200;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-Light.otf') format('truetype');
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-LightItalic.otf') format('truetype');
    font-style: italic;
    font-weight: 300;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-Regular.otf') format('truetype');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-Italic.otf') format('truetype');
    font-style: italic;
    font-weight: 400;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-Medium.otf') format('truetype');
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-MediumItalic.otf') format('truetype');
    font-style: italic;
    font-weight: 500;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-SemiBold.otf') format('truetype');
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-SemiBoldItalic.otf') format('truetype');
    font-style: italic;
    font-weight: 600;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-Bold.otf') format('truetype');
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('ibm plex sans/IBMPlexSans-BoldItalic.otf') format('truetype');
    font-style: italic;
    font-weight: 700;
}
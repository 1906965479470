.ui-lang-selector {
    height: $ui-lang-selector-height !important;
    line-height: $ui-lang-selector-height !important;

    &,
    * {
        color: $app-bar-text-color !important;
    }

    svg {
        fill: $app-bar-text-color !important;
    }
}
.header-horizontal {
    display: flex;
    margin-top: 5px;

    .profile-menu,
    .horizontal-btn {
        margin-right: 20px;
        height: $app-bar-horizontal-btn-height !important;

        button {
            background-color: $app-bar-horizontal-btn-background-color !important;
            height: $app-bar-horizontal-btn-height !important;
            line-height: $app-bar-horizontal-btn-height !important;

            span {
                color: $app-bar-horizontal-btn-text-color !important;
            }
        }
    }
}

.header-horizontal-profile-name {
    color: $primary;
}

.logo-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    padding-top: 0px;
    letter-spacing: 0px;
    font-size: 24px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    height: 64px;
    line-height: 64px;
    flex: 1 1 0%;
}

.userMenu {
    display: none;
}

@media (min-width: 768px) {
    .userMenu {
        display: block;
    }
}
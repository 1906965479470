.login-paper {
    width: 100%;
    min-height: calc(100vh - 64px);
    text-align: center;
    display: inline-block;
    .login-paper-inner {
        margin: 20px;
    }
}

.flex-content-in-big-dimensions {
    display: flex;
    
    .flex-content-dimensions {
        flex-basis: 100%;
        @media (#{$tablet}) {
            flex-basis: 50%;
        }
    }
}

.paper-container {
    min-height: calc(100vh - 144px);
    padding: 40px;
}

.products-container {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.help-dialog {
    position: fixed;
    right: 0;
    bottom: 60px;
    width: 280px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 13px #aaa;

    z-index: 999;
    text-align: center;
    border-radius: $border-radius;
    background-color: $white;
    height: fit-content;
    align-self: center;
    padding: 2rem 4rem;

    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    h2 {
        font-size: 15pt;
        font-weight: 400;
        margin-bottom: 2rem;
    }

    .hide-button {
        position: absolute;
        float: left;

        left: 0;
        top: 15px;
        background-color: $primary;
        fill: $white !important;
        border-radius: 0 6px 6px 0;
        width: 32px !important;
        height: 32px !important;
        cursor: pointer;
    }

    &.folded {
        width: 32px;
        padding: 0;
        bottom: 240px;
        right: 15px;

        .hide-button {
            border-radius: 32px 0 0 32px;
            width: 48px !important;
            height: 48px !important;
            right: 48px;
        }
    }

    p {
        a {
            font-weight: 500;
        }

        margin: .5rem 0;
        color: $primary;
    }
}
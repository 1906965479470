.price-comparator-gas {
    .header {
        .image {
            background: no-repeat top left/120px url("../../components/GasPriceComparator/icons/price-comparator-gas.png");
            width: 145px;
            height: 170px;
            float: left;
        }

        h2 {
            padding-top: 0.15em;
            font-size: 36px;
            font-weight: 700;
        }

        p {
            font-size: 19px;
            font-weight: 400;
        }

        .mode-selector {
            margin-top: 2rem;
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
        }
    }

    .mode-selector-panel {
        margin-top: 2rem;
        padding: 2.5rem;
    }

    .help-panel {
        margin-top: 2rem;
        padding: 2.5rem;
    }

    .auto-form {
        display: flex;

        &>div:nth-child(1) {
            flex: 4 0;
        }

        &>div:nth-child(2) {
            margin-left: 2rem;
            flex: 1 0;
        }
    }

    .submit-btn {
        margin-top: 2.5rem;
    }

    .invoice-upload {
        padding: 2rem 0;
    }

    .result {
        .annual-saving-title {
            font-size: 25px;
            text-align: center;
        }

        .annual-saving-value {
            font-weight: 700;
            font-size: 84px;
            text-align: center;
            margin-bottom: 3rem;
        }

        .warning-note {
            font-style: italic;
            font-size: 9pt;
            margin: 0 2em 2em;
            padding: 1em;
            color: #555;
            font-weight: 300;
            border-radius: 15px;
            background-color: #efefef;
        }

        .saving-detail {
            color: #808080;
            padding-left: 5%;
            font-size: 13px;

            .saving-detail-note {
                color: $primary;
                font-weight: 300;
                font-size: 14px;
            }

            .saving-detail-title {
                color: $primary;
                font-weight: 400;
                font-size: 17px;
            }

            .power-sum,
            .energy-sum,
            .other-sum {
                margin-top: 3rem;
                margin-bottom: 4px;
                font-weight: 600;
                font-size: 20px;
            }

            .other-sum {
                margin-top: 1.5rem;
            }

            .tax,
            .special-tax {
                text-align: right;
                margin-top: 1rem;

                .amount {
                    margin-left: 1rem;
                    font-size: 20px;
                    font-weight: 600;
                }
            }

            .total {
                margin-top: 1.5rem;
                font-size: 22px;
                font-weight: 600;
                text-align: right;
            }
        }

        .summary {
            font-weight: 500;
            font-size: 20px;
            padding: 2.5rem 5rem 2.5rem 2.5rem;
            margin-right: 5%;
            border-radius: $border-radius;
            background-color: #efefef;
            cursor: pointer;

            h3,
            h4 {
                color: $white !important;
                margin: 0;
            }

            h3 {
                font-size: 26px;
                line-height: 1.2;
            }

            h4 {
                margin-top: 1.5rem;
                font-size: 17px;
            }
        }

        .contract-now {
            .see-our-products-btn {
                margin: 3rem auto;
                margin-right: 5%;
                display: flex;
                outline: 0;
                position: relative;
                justify-content: center;

                span {
                    font-size: 16px !important;
                }
            }

            .contract-now-btn {
                margin: auto;
                margin-right: 5%;
                display: flex;
                outline: 0;
                position: relative;
                justify-content: center;
            }

            .secondary-btn {
                background-color: $secondary !important;
            }
        }
    }

    .contact-container {
        text-align: center;
        margin-top: 1rem;
        margin-left: 5%;
        margin-right: 5%;

        &>img {
            height: 80px;
        }

        h1 {
            font-weight: 700;
            font-size: 27px;
            margin-bottom: 3rem;
        }

        h2 {
            font-size: 25px;
            margin-bottom: 4rem;
        }
    }

    .btn-back {
        background-color: $secondary !important;
        padding: 0px !important;
        border-radius: $border-radius * 3;
        border: 0px none !important;
    }

    .btn-back-icon {
        width: 48px !important;
        height: 48px !important;
        color: $white !important;
    }

    // Mobile
    @media (max-width: 767px) {
        .header {
            .image {
                background-size: 50px;
                width: 66px;
                height: 80px;
            }

            h2 {
                font-size: 19px !important;
            }

            p {
                font-size: 15px !important;
            }
        }

        .auto-form {
            flex-direction: column;

            &>div:nth-child(1) {
                order: 2;
            }

            &>div:nth-child(2) {
                margin-left: 0;
                order: 1;
            }
        }

        .result {
            .annual-saving-value {
                font-size: 20px;
            }

            .annual-saving-value {
                font-size: 50px;
            }

            .saving-detail {
                padding-left: 0;
            }

            .summary {
                margin-top: 3rem;
                margin-right: 0;
            }

            .contract-now {
                margin-top: 3rem;

                p,
                .contract-now-btn {
                    margin-right: 0;
                }
            }

            .btn-back {
                float: none;
                width: 32px !important;
                height: 32px !important;
            }

            .btn-back-icon {
                width: 32px !important;
                height: 32px !important;
            }
        }

        .contact-container {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
.product-col {
    display: block !important;
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;

    @media (#{$tablet}) {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }
    padding: 12px;

    > div {
        display: block !important;
        padding: 20px;
        background-color: #fff;
        box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.2), 0px 0px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }
    
    h3 {
        margin-top: 0;
        margin-bottom: 1.5rem;
        font-size: 22px;
        font-weight: 500;
    }

    p,
    .description {
        font-size: 12px;
        color: #888;
        margin: 1rem 0 1.5rem;
        
        a {
            font-size: 16px;
            position: relative;
            z-index: 3;
        }
    }
}
@import '../variables.module';

@import 'fonts/roboto';
@import 'fonts/roboto-slab';

@import 'misc/typography';
@import 'misc/links';
@import 'misc/screens';

@import 'components/uiLang';
@import 'components/product';
@import 'components/altHome';
@import 'components/powerCalc';
@import 'components/helpPanel';
@import 'components/smartTable';
@import 'components/containers';
@import 'components/helpDialog';
@import 'components/leadContact';
@import 'components/cookieDialog';
@import 'components/togglePicture';
@import 'components/contractation';
@import 'components/signupContainer';
@import 'components/headerHorizontal';
@import 'components/priceComparatorGas';
@import 'components/priceComparatorElectricity';
//@import 'components/powerCalc'; //NOT IMPORTED FOR NOW


@import '../overrides/style';
  

body {
  position: relative;
  background-color: $gray-100 !important;

  margin: 0px;
  line-height: 1.42857143;
  color: #333333;
  
  font-family: $base-font;
  h1, h2, h3, h4 {
    font-weight: 300;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;/* Custom styles for new contract form product selector */

  .helper-text {
      font-size: 12px;
      color: #888;
  }
  
  label.field-label {
      margin-top: 1.5rem;
      font-size: 12px;
  }
  
}

p {
  margin: 0;
}

a {
  color: $primary;
}

.helper-text {
    font-size: 12px;
    color: #888;
}

label.field-label {
    margin-top: 1.5rem;
    font-size: 12px;
}

.actions div {
  margin-bottom: 7px;
}

.notification div {
  line-height: 20px;
}
.lead-contact-form {
    background-color: #efefef;
    border-radius: 30px;
    margin-top: 2.5rem;
    padding: 3.5rem 3.5rem 3.5rem 3.5rem;
    display: flex;
    flex-wrap: wrap;
    text-align: left;

    .left-column {
        flex: 1 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-right: 3rem;
        padding-right: 1rem;
        text-align: left;
        border-right: 1px solid #aaa;

        div {
            text-align: center;
        }
    }

    .form {
        flex: 2 0px;

        .select-field {
            text-align: left;
        }

        .attachment {
            margin-top: 2rem;
            text-align: left;
        }

        .submit-btn {
            text-align: left;
        }
    }
}

.lead-contact-footer {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 3.5rem;
    font-size: 13px;

    .phone-container {
        display: flex;
        align-items: center;

        img {
            height: 50px;
        }

        .phone,
        .schedule {
            padding-left: 0.5rem;
        }

        .phone {
            font-weight: 600;
        }
    }

    .email-container {
        display: flex;
        align-items: center;
        padding-left: 3rem;

        img {
            height: 50px;
        }

        .email {
            font-weight: 600;
            padding-left: 0.5rem;
        }
    }
}

// Mobile
@media (max-width: 767px) {
    .lead-contact-form {
        flex-direction: column;

        .left-column {
            margin-right: 0;
            border: 0 none;

            .primary-btn,
            .secondary-btn {
                margin: 1rem 0;
            }
        }
    }

    .lead-contact-footer {
        .email-container {
            padding-left: 0;
        }
    }
}
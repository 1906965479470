.cookie-dialog {
    bottom: 0;
    padding: 0 !important;
    top: auto !important;

    .react-cookie-law-accept-btn {
        background-color: $primary !important;
        border: none;
        color: white !important;
        font-size: 12pt !important;
        margin-left: 40px !important;
        padding: 7px 10px !important;
    }

    .react-cookie-law-msg {
        padding: 0 15% !important;
    }

    .react-cookie-law-container {
        background-color: white;
        padding: 1.5em 1em;
        max-width: inherit !important;
        text-align: center;
    }

    .react-cookie-law-button-wrapper {
        display: inline;
        float: none !important;
    }

    .react-cookie-law-select-pane {
        display: block !important;
    }

    .react-cookie-law-option-wrapper {
        float: none !important;

        label {
            color: black !important;
            padding: 5px 0px 0px 20px !important;
        }
    }
}

.default-cookies-dialog {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    padding: 1.5em 1em;
    z-index: 100000;

    .information-text {
        padding: 0 15% 0 15%;
        margin-bottom: 10px;
        font-size: 10pt;
        font-weight: 400;
        line-height: 130%;
        color: black;
    }

    .container-element {
        margin-left: 10px;
    }

    .link>a {
        text-decoration: underline;
        color: black;
        font-size: 13px;
    }
}